import { FC } from 'react';
import Pagination from 'antd/lib/pagination';
import { GetEventsCountQueryHookResult, GetVenuesCountQueryHookResult } from '../../../graphql/types';
import { checkPage } from '../../utils';

interface FooterProps {
  page: 'Events' | 'Venues';
  handlePageChange: (page: number) => Promise<void>;
  eventsCounter?: GetEventsCountQueryHookResult;
  venuesCounter?: GetVenuesCountQueryHookResult;
}

const Footer: FC<FooterProps> = ({ page, handlePageChange, eventsCounter, venuesCounter }) => {
  const checkEvents = eventsCounter && eventsCounter.data && eventsCounter.data.countEvents != 0;
  const checkVenues = venuesCounter && venuesCounter.data && venuesCounter.data.countVenues != 0;
  const counter = checkEvents && eventsCounter.data ? eventsCounter.data.countEvents : checkVenues && venuesCounter.data ? venuesCounter.data.countVenues : 0;

  return (
    <div className="footer-container">
      {checkEvents || checkVenues ? (
        <Pagination
          pageSize={checkPage(page).isEvents ? 10 : 15}
          total={counter - (checkPage(page).isEvents ? 10 : 15)}
          className="footer"
          onChange={e => handlePageChange(e)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Footer;
