import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import {
  EventsSortCriteria,
  EventType,
  SortDirection,
  useGetEventsCountQuery,
  useGetEventsDataQuery,
  useGetSignedUserDataQuery,
  useSyncUserPreferencesMutation,
} from '../../graphql/types';
import Footer from '../components/Content/Footer';
import Main from '../components/Content/Main';
import Layout from '../components/Layout/Index';
import LoadingData from '../components/StateScreens/LoadingData';
import Joyride, { CallBackProps } from 'react-joyride';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const user = useGetSignedUserDataQuery({
    fetchPolicy: 'no-cache',
  });

  const { t } = useTranslation('page');
  const isRootAdmin = user.data?.signedInUser?.isRootAdmin;
  const router = useRouter();
  const joyrideRef = useRef<Joyride>(null);
  const [syncUserPreferences] = useSyncUserPreferencesMutation();

  const [selectedPage, setSelectedPage] = useState<{
    id: string;
    name: string;
  }>({
    id: '',
    name: '',
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelectedPage({
        id: localStorage.getItem('page_id') || '',
        name: localStorage.getItem('page_name') || '',
      });
    }
  }, [localStorage.getItem('page_id'), localStorage.getItem('page_name')]);

  useEffect(() => {
    if (!user.data?.signedInUser && !user.loading) {
      router.replace('/login/with-email');
    }
  }, [user.data]);

  const events = useGetEventsDataQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 10,
      filter: {
        managedBySignedInBusinessUser: !isRootAdmin,
        type: [EventType.Local, EventType.Livestream, EventType.Private],
        startDateAfter: new Date().toDateString(),
      },
      sortBy: EventsSortCriteria.Date,
      sortDirection: SortDirection.Ascending,
    },
  });

  const counter = useGetEventsCountQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        managedBySignedInBusinessUser: !isRootAdmin,
        type: [EventType.Local, EventType.Livestream, EventType.Private],
        startDateAfter: new Date().toDateString(),
      },
    },
  });

  useEffect(() => {
    if (user.data?.signedInUser?.venueAccess && user.data?.signedInUser?.venueAccess.length > 0 && (selectedPage.id === '' || selectedPage.name === '')) {
      setSelectedPage({
        id: user.data?.signedInUser?.venueAccess[0].venue?.id || '',
        name: user.data?.signedInUser?.venueAccess[0].venue?.name || '',
      });
    }
  }, [user.data]);

  const noData = !events?.data?.events || events.error != undefined || counter?.data?.countEvents === 0;

  const handleSearch = async (input: string) => {
    await events?.refetch({ search: input });
    await counter.refetch({ search: input });
  };

  const handlePageChange = async (page: number) => {
    events.refetch({ offset: page === 1 ? 0 : page * 10 });
  };

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type, step } = data;

    if (action === 'skip' || action === 'close') {
      syncUserPreferences({
        variables: {
          preferences: {
            onboarding: true,
          },
        },
      })
        .then(() => {})
        .catch(() => {});
    }

    if (type === 'step:after' && index === 1 && action !== 'skip' && action !== 'prev') {
      router.push('/pages');
    }
  };

  const reload = async () => {
    await events.refetch();
  };

  useEffect(() => {
    if (!user.loading && user.data?.signedInUser?.venueAccess?.length === 0) {
      router.push('/onboarding');
    }
  }, [user.data]);

  return (
    <>
      {!user.data?.signedInUser?.preferences?.onboarding && !user.loading ? (
        <Joyride
          ref={joyrideRef}
          continuous={true}
          callback={handleCallback}
          showSkipButton={true}
          hideCloseButton
          locale={{
            next: t('onboarding.joyride.next'),
            skip: t('onboarding.joyride.skip'),
            last: t('onboarding.joyride.next'),
          }}
          run={true}
          floaterProps={{
            autoOpen: true,
          }}
          styles={{
            options: {
              arrowColor: '#fff',
              backgroundColor: '#fff',
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: '#8849d6',
              textColor: '#000',
              width: 900,
              zIndex: 1000,
            },
          }}
          steps={[
            {
              content: t('onboarding.joyride.welcome'),
              target: 'body',
              placement: 'center',
              styles: {
                spotlight: {
                  accentColor: 'black',
                },
              },
            },
            {
              content: t('onboarding.joyride.events'),
              target: '.nav-links h2:nth-child(2)',
              styles: {
                spotlight: {
                  accentColor: 'black',
                },
              },
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Layout>
        <Main page="Events" events={events} noData={noData} handleSearch={handleSearch} eventsCounter={counter} reload={reload} />
        {!noData && !counter.loading ? <Footer page="Events" handlePageChange={handlePageChange} eventsCounter={counter} /> : <></>}
      </Layout>
    </>
  );
};

export default Home;
